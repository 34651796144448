import { Divider } from '@nbit/arco'
import { t } from '@lingui/macro'
import { useUserStore } from '@/store/user'
import { logRegisterTypeEnum } from '@/constants/user'
import Icon from '@/components/icon'
import styles from './index.module.css'

type GameThirdPartyType = {
  /** 提示语参数 */
  dividerText?: string
  /** app 文本参数 */
  appleBtnText?: string
  /** google 文本参数 */
  googleBtnText?: string
  /** app 点击方法 */
  handleAppleLogin: () => void
  /** google 点击方法 */
  handleGoogleLogin: () => void
  /** 类型切换方法 */
  onChange?: (e: logRegisterTypeEnum) => void
  /** 类型参数 */
  data?: {
    key: string
    name: string
    type: logRegisterTypeEnum
    icon: string
    isHide?: boolean
  }[]
}

function GameThirdParty(props: GameThirdPartyType) {
  const { logOrRegisterType } = useUserStore()
  const { dividerText, handleAppleLogin, handleGoogleLogin, onChange } = props

  const dataList = props?.data?.filter(res => res.isHide)

  return (
    <div className={styles.scoped}>
      <Divider>{dividerText || t`user.third_party_01`}</Divider>

      <div className="third-party">
        <div className="third-party-button" onClick={handleAppleLogin}>
          <div className="icon-button">
            <Icon name="login_icon_apple" hasTheme />
          </div>
          {'Apple'}
        </div>
        <div className="third-party-button" onClick={handleGoogleLogin}>
          <div className="icon-button">
            <Icon name="login_icon_google" />
          </div>
          {'Google'}
        </div>
        {dataList?.map(item => {
          if (item?.type === logOrRegisterType) return
          return (
            <div key={item?.key} className="third-party-button" onClick={() => onChange?.(item?.type)}>
              <div className="icon-button">
                <Icon name={item?.icon} />
              </div>
              <span className="button-name">{item?.name || ''}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GameThirdParty
