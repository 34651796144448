import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import GameThirdParty2z from '@/features/recreation/theme/2z/game-third-party'
import GameThirdPartyOk from '@/features/recreation/theme/ok/game-third-party'

export default function GameThirdParty(props) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <GameThirdParty2z {...props} />
    case InitThemeColor.ok:
      return <GameThirdPartyOk {...props} />
    default:
      return <GameThirdParty2z {...props} />
  }
}
