import Icon from '@/components/icon'
import { t } from '@lingui/macro'
import { Button, Divider } from '@nbit/arco'
import styles from './index.module.css'

type GameThirdPartyType = {
  /** 提示语参数 */
  dividerText?: string
  /** app 文本参数 */
  appleBtnText?: string
  /** google 文本参数 */
  googleBtnText?: string
  /** app 点击方法 */
  handleAppleLogin: () => void
  /** google 点击方法 */
  handleGoogleLogin: () => void
}

function GameThirdParty(props: GameThirdPartyType) {
  const { dividerText, appleBtnText, googleBtnText, handleAppleLogin, handleGoogleLogin } = props
  return (
    <div className={styles.scoped}>
      <Divider>{dividerText || t`user.third_party_01`}</Divider>

      <div className="third-party">
        <Button type="default" icon={<Icon name="login_icon_apple" hasTheme />} onClick={handleAppleLogin}>
          {appleBtnText || t`user.third_party_02`}
        </Button>

        <Button type="default" icon={<Icon name="login_icon_google" />} onClick={handleGoogleLogin}>
          {googleBtnText || t`user.third_party_03`}
        </Button>
      </div>
    </div>
  )
}

export default GameThirdParty
