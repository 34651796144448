import { Button, Divider } from '@nbit/arco'
import { t } from '@lingui/macro'
import { useUserStore } from '@/store/user'
// TODO: 需要外部化处理 firebase
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'
import { getThirdPartyConfig } from '@/apis/user'
import { UserVerifyTypeEnum, SignInWithEnum, logRegisterTypeEnum } from '@/constants/user'
import Icon from '@/components/icon'
import { useMount } from 'ahooks'
import GameThirdParty from '@/features/recreation/theme/components/game-third-party'
import styles from './index.module.css'

let googleProvider
let appleProvider
let auth
function ThirdParty(props) {
  const { logOrRegisterType, setLogOrRegisterType } = useUserStore()
  const { onSuccess, dividerText, appleBtnText, googleBtnText } = props
  const getConfigInfo = async () => {
    const res = await getThirdPartyConfig({})
    if (res.isOk) {
      initializeApp(res.data!)
      googleProvider = new GoogleAuthProvider()
      appleProvider = new OAuthProvider('apple.com')

      auth = getAuth()
    }
  }

  const handleGoogleLogin = async () => {
    /** google 登录弹窗 */
    signInWithPopup(auth, googleProvider)
      .then(result => {
        const user = result.user as any
        const type = user.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone
        const params = {
          loginType: SignInWithEnum.google,
          accessToken: user.accessToken,
          account: user.email || user.phoneNumber,
        }
        onSuccess && onSuccess(type, params)
      })
      .catch(error => {
        const credential = GoogleAuthProvider.credentialFromError(error)
        console.error(credential)
      })
  }

  const handleAppleLogin = async () => {
    appleProvider.addScope('email')
    appleProvider.addScope('name')
    /** apple 登录弹窗 */
    signInWithPopup(auth, appleProvider)
      .then(result => {
        const user = result.user as any
        const type = user.email ? UserVerifyTypeEnum.email : UserVerifyTypeEnum.phone
        const params = {
          loginType: SignInWithEnum.apple,
          accessToken: user.accessToken,
          account: user.email || user.phoneNumber,
        }
        onSuccess && onSuccess(type, params)
      })
      .catch(error => {
        const credential = OAuthProvider.credentialFromError(error)
        console.error(credential)
      })
  }

  const onChange = (item: logRegisterTypeEnum) => {
    setLogOrRegisterType(item)
  }

  useMount(() => {
    getConfigInfo()
  })
  return (
    <GameThirdParty
      onChange={onChange}
      handleAppleLogin={handleAppleLogin}
      handleGoogleLogin={handleGoogleLogin}
      data={props.data}
      dividerText={dividerText}
      appleBtnText={appleBtnText}
      googleBtnText={googleBtnText}
    />
  )
}

export default ThirdParty
